<template>
  <PageWithLayout>
    <Tab
      :tabData="viewModel.tabData"
      :value.sync="viewModel.tabData.value"
      @update:value="value => viewModel.onUpdateTab(value)"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="searchData.dataList"
      :paginationData="searchData.paginationData"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)"
      @onSearch="viewModel.onSearch()"
    >
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          @onClickBtn="viewModel.onClickRegister()"
        />
      </template>
    </Board>
    <template v-slot:popup>
      <MorePointRegisterPopup
        v-if="viewModel.isMorePointRegisterPopup"
        :isModify="viewModel.isModify"
        :isVaild="viewModel.validatedResult()"
        :model.sync="viewModel.model"
        :tabKeyword="viewModel.tabData.dataList.find(item => item.id === viewModel.tabData.value).keyword"
        @onClickClose="viewModel.onClickCloseMorePointRegisterPopup()"
        @onClickComplete="viewModel.onClickCompleteMorePointRegisterPopupp()"
      />
    </template>
    <Loading v-if="viewModel.loading" />
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Loading from '@/components/common/loading/Loading';
// popup
import MorePointRegisterPopup from '@/views/app/morePoint/view/popup/MorePointRegisterPopup';
// viewModel
import MorePointListViewModel from '@/views/app/morePoint/viewModel/MorePointListViewModel';

export default {
  name: 'MorePointList',
  components: {
    PageWithLayout,
    Board,
    Tab,
    Button,
    MorePointRegisterPopup,
    Loading
  },
  data() {
    return {
      viewModel: new MorePointListViewModel(),
    };
  },
  computed: {
    searchData() {
      return this.viewModel.searchData[this.viewModel.tabData.value];
    },
  },
  beforeMount() {
    this.viewModel.init(this.$route.query);
  },
};
</script>
<style scoped>
.board_comm >>> td .txt_tbl .txt_point{display:inline-block;margin-left:6px;color:#8F69DD;vertical-align:top}
</style>
