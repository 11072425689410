
import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class AdvertisementModel {
  constructor() {
    this.id = null;
    this.target = 'ALL';
    this.title = '';
    this.description = '';
    this.useDeeplink = false;
    this.link = '';
    this.imageUrl = '';
    this.imageUrlList = [];
    this.posiiton = 1;
    this.status = 'REGISTERED';
    this.createdAt = '';
  }
  setTarget(target){
    this.target = target
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      id,
      code,
      target,
      title,
      description,
      useDeeplink,
      link,
      imageUrl,
      posiiton,
      status,
      createdAt,
    } = obj;
    this.code = code;
    this.target = target;
    this.title = title;
    this.description = description;
    this.useDeeplink = useDeeplink;
    this.link = link;
    this.imageUrl = imageUrl;
    if(imageUrl){
      this.imageUrlList = [
        {
          src : imageUrl,
        }
      ]
    }
    this.posiiton = posiiton;
    this.status = status;
    if(id){
      this.id = id;
      this.createdAt = createdAt;
    }
  }
  getData() {
    let obj = {
      target: 'ALL',
      title: this.title,
      description: this.description,
      useDeeplink: this.useDeeplink,
      link: this.link,
      imageUrl: this.imageUrlList.length > 0 ? this.imageUrlList[0].src : '',
      posiiton: this.posiiton,
      status: this.status,
    };
    return obj;
  }
}
