import { GET_REQUEST, POST_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertDateFormat, dateDiff } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import MorePointModel from '@/views/app/morePoint/model/MorePointModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

export default class MorePointListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'PARENTS', text: '포인트 벌기(부모)', keyword:'포인트' },
        { id: 'CHILD', text: '용돈 벌기(자녀)', keyword:'용돈' },
      ],
      option: {
        isCount: false,
      },
      value: 'PARENTS',
    };
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    },
    this.boardData = {
      title: `포인트 벌기 목록`,
      drawDataList: [
        {
          title:'순서',
          width:'58px',
          icon:{
            iconName:'drag',
            size:14,
            style:{margin:'12px'}
          },
        },
        {
          title: 'No.',
          width: '50px',
          value: 'rowIndex',
        },
        {
          title: '아이콘',
          width: '36px',
          value: 'imageUrl',
          img: {
            size: 'contain',
            width: '32px',
            height: '32px',
            position: {
              x: 'center',
              y: 'center',
            },
          },
        },
        {
          title: '문구',
          width: '200px',
          value: 'title',
          valueCustom: [
            {
              type: 'value',
              value: 'title',
            },
            {
              type: 'text',
              value: '<span class="txt_point">',
            },
            {
              type: 'value',
              value: 'description',
            },
            {
              type: 'text',
              value: '</span>',
            },
          ],
          isAlignLeft: true,
          isEllip: true,
        },
        
        {
          title: '딥링크',
          width: '40px',
          value: 'useDeeplink',
          filter: {
            name: 'convertIdToText',
            value: 'common_boolean_ox',
          },
        },
        {
          title: 'link',
          width: '200px',
          value: 'link',
          isAlignLeft: true,
          isEllip: true,
        },
        // {
        //   title: '타겟구분',
        //   width: '62px',
        //   value: 'target',
        //   filter: {
        //     name: 'convertIdToText',
        //     value: 'user_target',
        //   },
        //   class: {
        //     classColorFilter: {
        //       name: 'convertIdToColor',
        //       dataListName: 'user_target',
        //       prev: 'tc',
        //     },
        //   },
        // },
        {
          title: '상태',
          width: '80px',
          value: 'status',
          filter: {
            name: 'convertIdToText',
            value: 'more_point_status',
          },
          badge: {
            badgeColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'more_point_status',
            },
            badgeFill: false,
          },
        },
      ],
      option: {
        isTotal: true,
        isSize: false,
        isDrag: true,
        dragHandle:'.icon_drag',
        onEndDrag:(e,data) => this.onEndDrag(e,data)
      },
    };
    this.searchData = {
      PARENTS:{
        dataList:[],
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        responseDate:'',
        hasData:false
      },
      CHILD:{
        dataList:[],
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        responseDate:'',
        hasData:false
      }
    }
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };

    this.model = new MorePointModel();
    this.isMorePointRegisterPopup = false;
    this.isModify = false;

    this.valid = {
      imageList: {
        value: 'imageUrlList',
        valueType: 'array',
        isValid: false,
        validAlertDesc: '아이콘을 첨부해주세요',
      },
      code: {
        value: 'title',
        isValid: false,
        validAlertDesc: '문구를 입력해주세요',
      },
      priority: {
        value: 'description',
        isValid: false,
        validAlertDesc: '포인트문구를 입력해주세요',
      },
      link: {
        value: 'link',
        isValid: false,
        validAlertDesc: '링크 URL을 입력해주세요',
      },
    };

    this.loading = false;
  }

  init(query) {
    const { pageSize, page } = query;
    if (pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if (page) {
      this.searchParams.page = Number(page);
    }
    this.getList();
  }

  validatedResult() {
    return pageValidated(this.valid, this.model);
  }
  onEndDrag(e){
    if( e.newIndex === e.oldIndex ) return;
    // this.loading = true;
    const dragItemId = this.searchData[this.tabData.value].dataList[e.newIndex].id;
    const data = {
      oldPosition: e.oldIndex + 1,
      newPosition: e.newIndex + 1,
      badRequest: true
    }
    this.putMorePointPosition(dragItemId, data);
  }

  // 상세
  onClickRow(rowId, rowData) {
    this.model.setData(rowData);

    this.isModify = true;
    this.isMorePointRegisterPopup = true;
  }
  onClickCloseMorePointRegisterPopup() {
    this.isMorePointRegisterPopup = false;
    this.model = new MorePointModel();
  }
  onClickCompleteMorePointRegisterPopupp() {
    if (this.isModify) {
      this.putMorePoint();
    } else {
      this.postMorePoint();
    }
  }

  onClickRegister() {
    this.model = new MorePointModel();
    this.isModify = false;
    this.isMorePointRegisterPopup = true;
  }
  onUpdateTab(){
    this.getList()
  }

  onSearch() {
    this.getList();
  }
  // 목록 데이터
  getList() {
    const query = makeQueryStringByObject(this.searchParams);
    const pathName = `MORE_POINT_${this.tabData.value}_LIST`;
    let path = `${apiPath[pathName]}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.searchData[this.tabData.value].dataList = resultData.body.data.sort(function(a, b) {
          return a.position - b.position;
        });;
        this.searchData[this.tabData.value].paginationData.totalCount = resultData.body.data.length;
        // this.searchData[this.tabData.value].paginationData.totalCount = resultData.total;
        // this.searchData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
        this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
        this.searchData[this.tabData.value].hasData = resultData.total > 0;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 추가
  postMorePoint() {
    const path = `${apiPath[`MORE_POINT_${this.tabData.value}_REGISTER`]}`;
    // this.model.setTarget(this.tabData.value);
    const data = this.model.getData();
    const keyword = this.tabData.dataList.find(item => item.id === this.tabData.value).keyword;
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch('commonToast/fetchToastStart', `${keyword} 벌기가 등록되었습니다`);
        this.isMorePointRegisterPopup = false;
        this.onSearch();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 수정
  putMorePoint() {
    const path = `${apiPath[`MORE_POINT_${this.tabData.value}_MODIFY`].format(this.model.id)}`;
    // this.model.setTarget(this.tabData.value);
    const data = this.model.getData();
    const keyword = this.tabData.dataList.find(item => item.id === this.tabData.value).keyword;
    PUT_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch('commonToast/fetchToastStart', `${keyword} 벌기가 수정되었습니다`);
        this.isMorePointRegisterPopup = false;
        this.onSearch();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 순서 변경
  putMorePointPosition(dragItemId, data) {
    const path = `${apiPath[`MORE_POINT_${this.tabData.value}_POSITION`].format(dragItemId)}`;
    PUT_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        // this.loading = false;
        store.dispatch('commonToast/fetchToastStart', '순서가 변경되었습니다');
        this.isMorePointRegisterPopup = false;
        this.onSearch();
      },
      failed => {
        // this.loading = false;
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
