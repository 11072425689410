<template>
  <div class="wrap_loading">
    <img class="img_loading" src="/assets/images/coin_loading.gif" alt="" />
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>
<style>
.wrap_loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:9999;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0;
  white-space: nowrap;
  text-align: center;
}
.wrap_loading:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.img_loading {
  display: inline-block;
  width:100px;
  height:100px;
  vertical-align: middle;
  white-space: normal;
}
</style>
