<template>
  <Popup
    :title="`포인트벌기 ${isModify ? '수정':'등록'}`"
    :maxWidth="800"
    closeBtnText="닫기"
    :compeleteBtnText="isModify ? '수정':'등록'"
    :isDisabeldCompeleteBtn="isVaild"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="onClickComplete()">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr v-if="isModify">
          <th>생성일시</th>
          <td>{{ model.createdAt | convertDateFormat('YYYY-MM-DD HH:mm') }}</td>
        </tr>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="statusSync"
              :isSwitchLeft="true"
              text="활성화"
              offText="비활성화" />
          </td>
        </tr>
        <tr>
          <th>아이콘<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <FileUpload
              :dataList.sync="model.imageUrlList"
              :noticeArray="['모바일 전용으로 용량을 줄여 등록해주세요']"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              :size="[64,64]"
              sizeType="FIT"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"/>
          </td>
        </tr>
        <tr>
          <th>문구<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              :value.sync="model.title"/>
          </td>
        </tr>
        <tr>
          <th>포인트문구<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              :value.sync="model.description"/>
          </td>
        </tr>
        <tr>
          <th>링크<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <div class="group_form">
              <SwitchCheck
                :value.sync="model.useDeeplink"
                :isSwitchLeft="true"
                text="딥링크"
                offText="일반링크" />
              <Input
                :value.sync="model.link"/>
            </div>
            <p class="desc_info">💡 맵으로 이동해야하는 경우 딥링크를 사용해주세요<br/>콘텐츠 어드민에서 맵의 웹 주소 복사<br/>ex) https://content.lemontree.ai/world-map?mapUid=OBLnqd061xdXDIH8v6hwN&mapStyle=STEP&mapType=PLUS</p>
            <!--  -->
            <p class="desc_info">💡 외부 웹도 맵(정확히는 웹뷰)도 아닌 경우는 kerry에게 문의주세요</p>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name: 'MorePointRegisterPopup',
  mixins:[FileSaveMixin],
  components: {
    Popup,
    TableView,
    IconSvg,
    Input,
    SwitchCheck,
    FileUpload,
  },
  props: {
    isModify: Boolean,
    model: Object,
    isVaild: Boolean,
    tabKeyword: String
  },
  computed: {
    statusSync:{
      get(){
        return this.model.status === 'REGISTERED';
      },
      set(val){
        let result = this.model.status;
        switch(val){
          case true : { result = 'REGISTERED'; break }
          case false : { result = 'UNREGISTERED'; break }
        }
        this.model.status = result;
      },
    },
  },
  methods:{
    onClickComplete(){
      if(this.isModify){
        // const requestCallback = () => {
          const changedSrc = this.model.imageUrlList[0] ? this.model.imageUrlList[0].src : '';
          if(this.model.objRaw.image != changedSrc){
            this.onSave();
          }else{
            this.$emit('onClickComplete',this.model);
          }
        // }
        // this.$store.dispatch('commonAlert/fetchAlertStart', {
        //   alertMsg: `${this.tabKeyword} 벌기를 수정하시겠습니까?`,
        //   closeBtnText: '취소',
        //   compeleteBtnText: '확인',
        //   confirmCallBack: requestCallback,
        // });
      }else{
        this.onSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickComplete',this.model);
    }
  },
};
</script>
<style scoped>
.img_thmbnail {
  overflow: hidden;
  width:100%;
  max-width: 569px;
  max-height: 500px;
}
.img_thmbnail img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
